<template>
    <div id="courseTeaching">
        <div class="box">
            <div style="width: 100%;height: 60px;display: flex;justify-content: space-between;margin-top: 10px;">
            <div>
                <div v-for="(item,index) in daohangData" :key="index"  :class="indexYs == index?'whiteData':'default'" @click="daohangClick(index)">
                    {{ item.name }}
                </div>
            </div>
            <div>
                <!-- <el-button type="primary" round style="background-color: #76C8F6;border: none;" >编辑课程</el-button> -->
                <!-- <el-button type="primary" round style="background-color: #76C8F6;border: none;">开始上课</el-button> -->
            </div>
          </div>
          <div class="boxCneterData" >
            <p style="padding-top: 20px;">
                <span style="font-size: 16px;font-weight: 600;color: #76C8F6;;padding-left: 20px;">|</span><span style="padding-left: 10px;font-weight: 600;">班级数据</span>
            </p>
            
            <p style="padding: 20px;font-size: 14px;">
                <span>章名称：模块1 绪论</span>
                <span style="padding-left: 20px;">节名称：任务1 建筑力学研究对象</span>
                <span style="padding-left: 20px;">习题名称：第一章 第一节习题</span>
            </p>
            <p  style="padding-left: 20px;font-size: 14px;">
                <el-input style="width: 200px;" v-model="input" placeholder="学生姓名" size="mini" />
                <el-input style="width: 200px;margin-left: 20px;" v-model="input" placeholder="手机号" size="mini" />
                <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 20px;" size="mini" >搜索</el-button>
                <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 20px;float: right;margin-right: 20px;" size="mini" >重新发放</el-button>
                <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 20px;float: right;" size="mini" > 撤销发放</el-button>
            </p>
            <el-table :data="tableData" stripe style="width: 100%;height:500px" max-height="500px">
                <el-table-column type="selection" width="55" />
            <el-table-column type="index" width="30" />
            <el-table-column prop="name" label="学生姓名" />
            <el-table-column prop="time" label="手机号"  />
            <el-table-column prop="classroom" label="状态" />
            <el-table-column  label="操作">
              <template #default>
                <span style="cursor: pointer;color:#76C8F6">撤销发放</span>
              </template>
              
            </el-table-column>
          </el-table>
          <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
          <el-pagination small  v-model:current-page="currentPage4" layout="prev, pager, next"
            :total="20" />
        </div>
        </div>
        </div>
    </div>
</template>


<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,defineProps} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl} from "@/js/util"
import * as echarts from 'echarts';
export default {
    
    setup (props,ctx) {
        const router = useRouter();
        const store = useStore();
       
        const state = reactive({
            input:'',
            formInline:{
                user:'',
                region:''
            },
            tableData:[
        {
          name:'王毅',
          time:'12345321346',
          classroom:'已发放',

        },{
          name:'李梦',
          time:'12345321346',
          classroom:'已发放',

        },{
          name:'张三',
          time:'12345321346',
          classroom:'已考',

        },{
          name:'里斯',
          time:'12345321346',
          classroom:'已发放',
        }
      ],
            radio1:'1',
            indexYs:1,
            daohangData:[
                {
                    name:'数据'
                },{
                    name:'课后作业'
                },{
                    name:'考试'
                },{
                    name:'上课时间'
                },
            ],
           
        })


        const methods ={
            blackClick(){
                router.push('/home/afterClassExercises')
            },
            daohangClick(index){
                console.log(index);
                state.indexYs = index;
                if (state.indexYs == 0) {
                    router.push('/home/classDetails')
                }else if(state.indexYs == 1){
                    router.push('/home/afterClassExercises')
                }else if(state.indexYs == 2){
                    router.push('/home/examinationIndex')
                }else if(state.indexYs == 3){
                    router.push('/home/classTime')
                }

            },
        }
        onMounted(() => {

        })
        return {
      ...toRefs(state),
      ...methods,
    };
    }
    }

</script>
<style  lang="scss" scoped>
#courseTeaching{
    width: 100%;
            height: 800px;
            background: #F7F8FA;;
            .box{
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
            }
            .whiteData{
            width: 85px;
            height:30px;
            float: left;
            margin: 10px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            cursor: pointer;
            background: #fff;
        }
        .default{
            width: 85px;
            height:30px;
            float: left;
            margin: 10px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            cursor: pointer;
            background: #EDEDED;
        }
        .boxCneterData{
            width: 100%;
            height: 800px;
            background: #fff;
        }
}

</style>